import React from "react";
import agentImg from "../../assets/images/team/agent-13.jpg";
import { Link } from "react-router-dom";
import { nameBasedRoutes } from "../../router/public";
import { useTranslation } from "react-i18next";
import Cookies from "universal-cookie";
import { useState } from "react";
function AgentpersonalInfo({ agentInfo, agentId, agencyId, agencyInfo }) {


  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const cookies = new Cookies()
  const userLanguage = i18n?.language ?? cookies?.get("userLangKey") ?? 'en'

  const [iconSpace, setIconSpace] = useState(" +");

  return (
    <div className="agen-personal-info position-relative bgc-white default-box-shadow1 bdrs12 p30 mb30">
      <div className="widget-wrapper mb-0">
        <h6 className="title fz17 mb30">{t('GET MORE INFORMATION')}</h6>
        <div className="agent-single d-sm-flex align-items-center pb25">
          <div className="single-img mb30-sm">
            <img className="w90 bdrs6" src={agencyInfo?.logo ?? agentImg} alt="agent" />
          </div>
          <div className="single-contant ml20 ml0-xs">
            <h6 className="title mb-1">{agentInfo?.firstName ?? ''} {agentInfo?.lastName ?? ''}</h6>
            <div className="agent-meta mb10 d-md-flex align-items-center">
              {/* {
                agentInfo?.mobileNumber ? <Link to="#" className="text fz16">
                  <i className="fal fa-mobile pe-1" />
                  {iconSpace}{userLanguage == 'ar' ? agencyInfo?.countryCode.replace(/\+/g, "") : agencyInfo?.countryCode.replace(/\+/g, "")}   {userLanguage == 'ar' ? agentInfo?.mobileNumber : agencyInfo?.mobileNumber ?? ""}
                </Link> : ""
              } */}

              {
                agentInfo?.mobileNumber ? userLanguage == 'ar' ? <Link to="#" className="text fz16">
                  <i className="fal fa-mobile pe-1" />
                  <span>{" "}{agencyInfo?.mobileNumber} {agencyInfo?.countryCode.replace(/\+/g, "")}+</span>
                </Link> : <Link to="#" className="text fz16">
                  <i className="fal fa-mobile pe-1" />
                  {iconSpace}{userLanguage == 'ar' ? agencyInfo?.countryCode.replace(/\+/g, "") : agencyInfo?.countryCode.replace(/\+/g, "")}   {userLanguage == 'ar' ? agentInfo?.mobileNumber : agencyInfo?.mobileNumber ?? ""}
                </Link> : ""
              }
            </div>
            <Link
              to={`${nameBasedRoutes?.agencyDetails?.path}/${agencyId}${agencyInfo?.slug ? '/' + agencyInfo?.slug : ''}`}
              className="text-decoration-underline fw600 me-2"
            >
              {t('VIEW AGENCY')}
            </Link>
          </div>
        </div>
        <div className="d-grid">
          <Link
            to={`${nameBasedRoutes?.agentDetails?.path}/${agentId}${agentInfo?.slug ? '/' + agentInfo?.slug : ''}`}
            className="ud-btn btn-white2"
          >
            {t("AGENT PAGE")}
            <i className="fal fa-arrow-right-long" />
          </Link>
        </div>
      </div>
    </div>
  );
}
export default AgentpersonalInfo;
