import Cookies from "universal-cookie";
import React, { useState } from "react";
import InputText from "./InputText";
import { LoginSchema } from "../../validations/ValidationSchema";
import { getUserDataApi, signInAPI } from "../../services/userService";
import { Link } from "react-router-dom";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import storage from "../../helpers/storage";
import { useDispatch } from "react-redux";
import { setUser } from "../../redux/userProfileSlics";

import GoogleLoginButtonDefault from "./googleLoginButtonDefault"
import FacebookLoginButton from "./facebookLoginButton";

import LoaderMu from "./LoaderMu";
import { useTranslation } from "react-i18next";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { nameBasedRoutes } from "../../router/public";
import { trackCustomEvent } from "../../services/FacebookPixelEvent";

const SignInModal = ({ close, redirectProfile }) => {

  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const cookies = new Cookies();
  const dispatch = useDispatch();


  // remember me
  const rememberMeEmail = cookies.get("amakenUserEmail");
  const rememberMePassword = cookies.get("amakenUserPassword");
  const alreadyChecked = cookies.get("amakenUserChecked");
  const [rememberMe, setRemembeMe] = useState(!!alreadyChecked);

  const [loading, setLoading] = useState(false);


  // STATE FOR PASSWORD VISIBLE TOGGLE
  const [showEye, setShowEye] = useState(false);
  // TOGGLE EYE BUTTON FOR PASSWORD
  const showToggle = (event) => {
    setShowEye(event);
  };

  // Submit onboarding handleChange
  const handleSubmit = async (value, actions) => {
    submitForm(value);
  };

  // Submit form
  const submitForm = async (value) => {
    setLoading(true)
    const email = value.email;
    const password = value.password;

    if (rememberMe === false) {
      cookies.remove("amakenUserEmail");
      cookies.remove("amakenUserPassword");
      cookies.remove("amakenUserChecked");
    }
    // SOCIAL SIGN IN API
    signInAPI({
      username: email.toString(),
      password: password.toString(),
      panel: "USER",
      deviceType: "BROWSER",
    })
      .then((response) => {
        if (rememberMe === true) {
          cookies.set("amakenUserEmail", email);
          cookies.set("amakenUserPassword", password);
          cookies.set("amakenUserChecked", rememberMe);
        }

        storage.setToken(response?.id_token);
        storage.setData("userType", "USER");
        storage.setData("refresh-token", response?.id_token);
        trackCustomEvent('user_sign_in', { device_type: 'BROWSER', method: 'Email', Timestamp: new Date() });

        // FETCH USER DATA AFTER SUCCESS SIGN IN
        getUserDataApi()
          .then((userApiResponse) => {
            setLoading(false);
            close()
            // UPDATE REDUX STATE FOR USER DATA
            dispatch(
              setUser({ userData: userApiResponse, token: response?.id_token })
            );
            // SET USER LANGUAGE KEY

            cookies.remove("userLangKey")
            cookies.set("userLangKey", userApiResponse?.langKey)
            i18n.changeLanguage(userApiResponse?.langKey)

            if (userApiResponse?.profileComplete === false) {
              cookies.set("showProfileModal", true)
            } else {
              cookies.set("showProfileModal", false)
            }
            // if (redirectProfile) {
            //   navigate("/profile-user");
            // } else {
            //   navigate("/");
            // }
            // NAVIGATE TO HOME PAGE
            toast.success(response?.message ?? "Sign-in successful");
          })
          .catch((error) => {
            setLoading(false);
            toast.error(
              error?.response?.data?.error_description
                ? error?.response?.data?.error_description
                : error?.response?.data?.message ?? error?.message
            );
          });
      })
      .catch((error) => {
        setLoading(false);
        toast.error(
          error?.response?.data?.error_description
            ? error?.response?.data?.error_description
            : error?.response?.data?.message ?? error?.message
        );
      });
  };

  // SET FORM INITIAL DATA IF REMEMBER ME CHECKED
  const initialValues = {
    email: rememberMeEmail ? rememberMeEmail : "",
    password: rememberMePassword ? rememberMePassword : "",
    rememberCheck: rememberMeEmail && rememberMePassword ? true : false,
  };


  //***********Google & facebook Start****************** */
  /**
  * On facebook and google login response*/
  const onSocialGoogle = (response) => {
    //handleLoginResponse(response);
    toast.success('Social signin successful')
    close()
    // if (redirectProfile) {
    //   navigate("/profile-user");
    // } else {
    //   navigate("/");
    // }
  }


  //*************Google & facebook End*****************/

  return (
    <div className="container d-flex flex-column pageContainer  h-100 overflow-auto">
      <LoaderMu loading={loading} />
      <Formik
        validationSchema={LoginSchema}
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          handleSubmit(values, actions);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
          touched,
          isValid,
          errors,
          isSubmitting
        }) => (
          <Form onSubmit={handleSubmit} className="form-style1">
            {/* EMAIL */}
            <div className="d-flex">
              <div className="col-12 px-1">
                <InputText
                  autoFocus={true}
                  controlId="email"
                  label={t("EMAIL ADDRESS")}
                  value={values.email}
                  name="email"
                  maxLength="300"
                  errorsField={errors.email}
                  touched={touched.email}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  className="form-control"
                />
              </div>
            </div>
            {/* PASSWORD*/}
            <div className="d-flex">
              <div className="col-12 px-1">
                <div
                  className={`position-relative ${showEye ? "form-right-icon" : ""
                    }`}
                >
                  <InputText
                    controlId="password"
                    label={t("PASSWORD")}
                    value={values.password}
                    name="password"
                    maxLength="300"
                    type={showEye ? "text" : "password"}
                    errorsField={errors.password}
                    touched={touched.password}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    className="form-control"
                  // autoComplete="new-password"
                  />
                  <span className="form-label fz12 mb0 fw600 mx10 text-danger" />
                  <div onClick={() => setShowEye(!showEye)}>
                    {showEye ? (
                      <FaEye
                        size="22"
                        className="eyePosition"
                        onClick={() => showToggle(true)}
                      />
                    ) : (
                      <FaEyeSlash
                        size="22"
                        className="eyePosition"
                        onClick={() => showToggle(true)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* REMEMBER ME */}
            <div className="checkbox-style1  d-block d-sm-flex align-items-center justify-content-between mb10">
              <label className="custom_checkbox fz14 ff-heading">
                {t('REMEMBER ME')}
                <input
                  type="checkbox"
                  label={t("REMEMBER ME")}
                  checked={rememberMe ?? false}
                  onChange={(e) => setRemembeMe(e?.target?.checked)}
                />
                <span className="checkmark" />
                <span className="checkmark" />
              </label>
              <Link
                className="fz14 ff-heading"
                to={nameBasedRoutes?.forgotPassword?.path}
                role="button"
              >
                {t('FORGET PASSWORD?')}
              </Link>
            </div>
            {/*  HANDLE SUBMIT */}
            <div className="d-grid mb20 mt15">
              <button
                onClick={handleSubmit}
                className="ud-btn btn-thm"
                type="submit"
              >
                {t('SIGN IN')}
                <i className="fal fa-arrow-right-long" />
              </button>
            </div>
            <div className="hr_content mb20">
              <hr />
              <span className="hr_top_text">{t('OR')}</span>
            </div>
            <div className="row justify-content-center">
              {/* GOOGLE LOGIN */}
              <div className="col-11 mb-2">
                <GoogleLoginButtonDefault
                  close={close}
                  onLogin={onSocialGoogle}
                  setLoading={setLoading}>
                </GoogleLoginButtonDefault>
              </div>
              {/* FACEBOOK LOGIN */}
              <div className="col-11">
                <FacebookLoginButton
                  close={close}
                  onLogin={onSocialGoogle}
                  setLoading={setLoading}
                  type="0"
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>

  );
};

export default SignInModal;
