import React, { useEffect, useState } from 'react'
import { Footer } from "../../components/layouts/Footer/Footer";
import { useDispatch } from "react-redux";
import { setHeaderClass } from "../../redux/layoutSlice";
import { getFaqPageContent } from '../../services/cmsPageService';
import LoaderMu from '../../components/common/LoaderMu';
import { toast } from 'react-toastify';
import { Empty } from 'antd';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { nameBasedRoutes } from "../../router/public";
import SeoComponent from '../../components/seo/SeoComponent';
import { Accordion } from 'react-bootstrap';

export const Faq = () => {

  const { t } = useTranslation()
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [userFaqs, setUserFaqs] = useState([])


  // FETCH FAQ DATA
  const fetchFaqData = () => {
    setLoading(true)
    getFaqPageContent("faq-list").then(response => {

      setLoading(false)
      // FILTER USER FAQ'S FROM RESPONSE
      const userFaqList = response.filter(faq => faq.faqCategory.id === 3 && faq?.status === "ENABLED");
      setUserFaqs(userFaqList)
    }).catch((error) => {
      setLoading(false)
      toast.error(
        error?.response?.data?.error_description
          ? error?.response?.data?.error_description
          : error?.response?.data?.message ?? error?.message
      );
    })
  }
  // FETCH FAQ DATA
  useEffect(() => {
    dispatch(setHeaderClass({ className: "container" }))
    fetchFaqData()
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [i18n?.language])

  return (
    <div className="body_content">
      <SeoComponent
        title={t("FAQ_PAGE_TITLE")}
        meta_title={t("FAQ_PAGE_TITLE")}
        description={t("FAQ_PAGE_DESCRIPTION")}
        canonical={process.env.REACT_APP_WEBSITE_WEB_URL + nameBasedRoutes?.faq?.path}
      />
      <LoaderMu loading={loading} />
      {/* UI Elements Sections */}
      <section className="breadcumb-section pt60 pb30">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcumb-style1">
                <h2 className="title">{t("FREQUENTLY ASKED QUESTIONS")}</h2>
                <div className="breadcumb-list">
                  <Link to={nameBasedRoutes?.home?.path}>{t('HOME')}</Link>
                  <Link to={nameBasedRoutes?.faq?.path}>{t('FAQ')}</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* FAQ Section Area */}
      <section className="our-faq pb90 pt-0">
        <div className="container">
          <div className="row wow fadeInUp" data-wow-delay="300ms">
            <div className="col-lg-12">
              {/* USER FAQ'S */}
              <div className="ui-content">
                {/* <h4 className="title">{t("FAQ")}</h4> */}
                <div className="accordion-style1 faq-page mb-4 mb-lg-5">
                  <Accordion>
                    {
                      userFaqs && userFaqs?.length > 0 ? userFaqs?.map((userFaq, index) => {
                        return <Accordion.Item key={index + 1} eventKey={index}>
                          <Accordion.Header>
                            <span className="w-100 d-md-flex align-items-center">
                              <span className="mr10-sm">{userFaq?.question}</span>
                            </span>
                          </Accordion.Header>
                          <Accordion.Body>
                            <div dangerouslySetInnerHTML={{ __html: userFaq?.description }} />
                          </Accordion.Body>
                        </Accordion.Item>
                      }) : <Empty description={<span>{t("NO FAQ AVAILABLE")}</span>} />
                    }
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*  FOOTER */}
      <Footer />
    </div>
  );
};
