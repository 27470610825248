
import { Layout } from "../components/layouts/Layout";
import { ForgotPassword } from "../pages/Auth/ForgetPassword";
import { EmailVerify } from "../pages/Auth/EmailVerify"
import { Home } from "../pages/Home";
import { Searchbyamakenmap } from "../pages/Searchbyamakenmap";
import { ResetPassword } from "../pages/Auth/ResetPassword";
import { Agency } from "../pages/Agency";
import { About } from "../pages/About";
import { Faq } from "../pages/Faq";
import { Error404 } from "../pages/Error404";
import { Agencysingle } from "../pages/Agencysingle";
import { Agentsingle } from "../pages/Agentsingle";
import { Property3 } from "../pages/Property3";
import { Propertysingle } from "../pages/Propertysingle";
import { PrivacyPolicy } from "../pages/PrivacyPolicy";
import { TermsCondition } from "../pages/Terms_Condition";
import { PrivacyPolicyMobile } from "../pages/PrivacyPolicy/PrivacyPolicyMobile";
import { TermsConditionMobile } from "../pages/Terms_Condition/TermsConditionMobile";
import { FaqMobile } from "../pages/Faq/IndexMobile"
import Compare from "../pages/Compare";
import { AboutMobile } from "../pages/About/AboutMobile";
import { Profileuser } from "../pages/Profileuser";
import Blogs from "../pages/Blogs";
import BlogDetails from "../pages/BlogDetails";
// import { MultifactorAuth } from "../pages/Auth/MultifactorAuth";
// const basePath = process.env.REACT_APP_BASE_URL;

/**
 * Public Routes Available in App
 * @type {{}}
 */
export const publicRoutes = [
  {

    path: '/',
    element: <Layout />,
    children: [
      {
        path: `/`,
        element: <Home />,
      },
      {
        path: `/home`,
        element: <Home />,
      },
      {
        path: `/search-by-amaken-map`,
        element: <Searchbyamakenmap />,
      },
      {
        path: `/compare-properties`,
        element: <Compare />
      },
      {
        path: `/about`,
        element: <About />,
      },
      {
        path: `/faq`,
        element: <Faq />,
      },
      {
        path: `/terms-condition`,
        element: <TermsCondition />,
      },
      {
        path: `/privacy-policy`,
        element: <PrivacyPolicy />,
      },
      {
        path: `/agencies`,
        element: <Agency />,
      },
      {
        path: `/agency-detail/:id/:slug`,
        element: <Agencysingle />,
      },
      {
        path: `/agent-details/:id/:slug`,
        element: <Agentsingle />,
      },
      {
        path: `/properties`,
        element: <Property3 />,
      },
      {
        path: `/property-details/:id/:slug`,
        element: <Propertysingle />,
      },
      {
        path: `/email-verify`,
        element: <EmailVerify />,
      },
      {
        path: `/forgot-password`,
        element: <ForgotPassword />,
      },
      {
        path: `/reset-password`,
        element: <ResetPassword />,
      },
      {
        path: `/properties`,
        element: <Property3 />,
      },
      {
        path: `/property-details/:id/:slug`,
        element: <Propertysingle />,
      },
      {
        path: `/profile-user`,
        element: <Profileuser />
      },
      {
        path: '/blogs',
        element: <Blogs />
      },
      {
        path: '/blog-details/:id/:slug',
        element: <BlogDetails />
      }
    ],
  },
  {
    path: `*`,
    element: <Error404 />,
  },

  {
    path: '/privacy-policy/web-view',
    element: <PrivacyPolicyMobile />
  },
  {
    path: '/terms-condition/web-view',
    element: <TermsConditionMobile />
  },
  {
    path: '/faq/web-view',
    element: <FaqMobile />
  },
  {
    path: '/about/web-view',
    element: <AboutMobile />
  },
 
];

export const nameBasedRoutes = {
  home: {
    name: "Home",
    path: `/`,
  },
  agency: {
    name: "Agency",
    path: '/agencies'
  },
  agencyDetails: {
    name: "Agency Details",
    path: `/agency-detail`,
  },
  agentDetails: {
    name: "Agent Details",
    path: `/agent-details`,
  },
  forgotPassword: {
    name: "Forgot Passowrd",
    path: `/forgot-password`,
  },
  resetPassword: {
    name: "Reset Passowrd",
    path: `/reset-password`,
  },
  termsConditions: {
    name: "Terms and Condition",
    path: '/terms-condition'
  },
  privacyPolicy: {
    name: "PrivacyPolicy",
    path: "/privacy-policy"
  },
  propertyList: {
    name: "PropertyList",
    path: "/properties"
  },
  propertyDetails: {
    name: "PropertyDetails",
    path: "/property-details"
  },
  searchByMap: {
    name: "SearchByMap",
    path: "/search-by-amaken-map"
  },
  compareProperties: {
    name: "CompareProperties",
    path: "/compare-properties"
  },
  about: {
    name: "About",
    path: "/about"
  },
  faq: {
    name: "FAQ",
    path: "/faq"
  },
  blog: {
    name: "Blog",
    path: "/blogs"
  },
  blogDetails: {
    name: "BlogDetails",
    path: "/blog-details"
  }

}
