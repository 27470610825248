import React, { useEffect, useState } from "react";

import { Image, Skeleton, Spin } from "antd";
import { useTranslation } from "react-i18next";
function PropertyImageSection({ propertyImages }) {

  const { t } = useTranslation()

  const [previewVisible, setPreviewVisible] = useState(false)

  const [mainImage, setMainImage] = useState({})
  const [loading, setLoading] = useState(false)



  const [previewItems, setPreviewItems] = useState([])
  const [currentPreview, setCurrentPreview] = useState(0)

  const handleButtonClick = () => {
    setCurrentPreview(0)
    setPreviewVisible(true)
  };

  // useEffect(() => {
  //   // FILTER MAIN IMAGE AND SET IN STATE
  //   if (propertyImages && propertyImages?.length > 0) {

  //     const preloadImages = async () => {
  //       const loaded = [];

  //       await Promise.all(
  //         propertyImages.map(async (image) => {
  //           try {
  //             const response = await fetch(image.imageName, { mode: 'no-cors' });

  //             if (response.ok || response.type === 'opaque') {
  //               const blob = await response.blob();
  //               const imgUrl = URL.createObjectURL(blob);
  //               loaded.push(image.imageName);
  //               URL.revokeObjectURL(imgUrl);  // Clean up
  //             } else {
  //               console.error(`Failed to load: ${image.imageName}`);
  //             }
  //           } catch (error) {
  //             console.error(`Error loading image: ${image.imageName}`, error);
  //           }
  //         })
  //       );
  //     }

  //     preloadImages().then(() => {
  //       const mainImage = propertyImages.find((image) => image?.mainImage === true)
  //       setMainImage(mainImage)
  //       const previewItems = propertyImages.filter((image) => image?.mainImage === false).map((image) => image?.imageName)
  //       // setPreviewItems([mainImage?.imageName, ...previewItems])
  //     });

  //   }
  // }, [propertyImages])

  // PREVIOUS CODE 


  useEffect(() => {
    if (propertyImages && propertyImages?.length > 0) {
      const mainImage = propertyImages.find((image) => image?.mainImage === true)
      setMainImage(mainImage)
      const previewItems = propertyImages.filter((image) => image?.mainImage === false).map((image) => image?.imageName)
      setPreviewItems([mainImage?.imageName, ...previewItems])
    }
  }, [propertyImages])


  const customRender = (originalNode, info) => {

    const { index } = info;
    return (
      <div
        key={index}
        className="custom-image-wrapper"
        style={{ position: "relative" }}
      >



        {loading && <Skeleton.Image active style={{ width: 500, height:500 }} />}
        {/* <img
          src={originalNode.props.src}
          alt={`Gallery image ${index}`}
          style={{ width: "100%", height: "auto", objectFit: "cover" }}
          onLoad={() => setLoading(false)}
        /> */}
        <img
          src={originalNode.props.src}
          alt={`Gallery image ${index}`}
          style={{
            width: "100%",
            height: "auto",
            objectFit: "cover",
            display: loading ? "none" : "block", // Hide until loaded
          }}
          onLoad={() => {
            console.log(`Image ${index} loaded`);
            setLoading(false);
          }}
          onError={() => {
            console.error(`Failed to load image!`);
            setLoading(false); // Remove spinner on error
          }}
        />


      </div>
    );
  };

  return (
    <div className="row mb30 mt30 wow fadeInUp" data-wow-delay="300ms">
      <div className="col-sm-6">
        <div className="sp-img-content mb15-md">
          {/* MAIN IMAGE */}
          <div
            className="popup-img preview-img-1 sp-img"
          >
            <Image.PreviewGroup items={previewItems} mask={false}
              preview={
                {
                  visible: previewVisible,
                  current: currentPreview,
                  onChange: (e) => { setCurrentPreview(e); setLoading(true) },
                  // onChange: (newIndex) => {
                  //   const img = document.createElement('img');  // Use DOM API
                  //   img.src = previewItems[newIndex];
                  //   img.onloadstart = () => {console.log(true)}
                  //   img.onload = () => {setCurrentPreview(newIndex); };  // Switch only when image is loaded

                  //   img.onerror = () => console.error("Failed to load image:", previewItems[newIndex]);
                  // },
                  onVisibleChange: (state) => {
                    setCurrentPreview(0)
                    setPreviewVisible(state)
                  },
                  imageRender: customRender, // Custom rendering
                }
              } >
              <Image
                src={mainImage?.imageName}
                className="w-100"
                alt="Properties For You"
                preview={
                  { src: mainImage?.imageName, mask: false }
                }
              />
            </Image.PreviewGroup>

          </div>
        </div>
      </div>
      {/* ALL REMAINING IMAGES */}

      <div className="col-sm-6">
        <div className="row">
          {
            propertyImages && propertyImages?.length > 0 ?

              propertyImages.filter((image) => image?.mainImage === false).slice(0, 4).map((image, index) => (
                <div key={image?.id} className="col-6 ps-sm-0">
                  <div className="sp-img-content">

                    <div
                      className="popup-img preview-img-2 sp-img mb10"
                      href="images/listings/listing-single-2.jpg"
                    >
                      {
                        index === 3 ? <button
                          // href="images/listings/listing-single-6.jpg"
                          className="all-tag popup-img border-none z-3"
                          onClick={handleButtonClick}
                        >
                          {t("SEE ALL")}
                        </button> : ''
                      }

                      <Image.PreviewGroup items={previewItems}
                        preview={
                          {
                            current: currentPreview,
                            onChange: (e) => { setCurrentPreview(e) },
                            onVisibleChange: () => setCurrentPreview(index + 1)
                          }
                        }
                      >
                        <Image
                          src={image?.thumbnailImageName}
                          className="w-100"
                          alt="Properties For You"
                          preview={{ src: image?.imageName, mask: false }}
                        />
                      </Image.PreviewGroup>
                    </div>
                  </div>
                </div>
              )) : ""}


        </div>

      </div>

    </div>
  );
}

export default PropertyImageSection;
