import React, { useEffect } from "react";
import { Footer } from "../../components/layouts/Footer/Footer";
import HomeBanner from "../../components/homepage/HomeBanner";
import ExploreProperties from "../../components/homepage/ExploreProperties";
import PropertiesForYou from "../../components/homepage/PropertiesForYou";
import PropertiesCities from "../../components/homepage/PropertiesCities";
import TrustedAgencies from "../../components/homepage/TrustedAgencies";
import LoginAgency from "../../components/homepage/LoginAgency";
import { useSelector } from "react-redux";
import ReactGA4 from "react-ga4"
import { Helmet } from "react-helmet";
import SeoComponent from "../../components/seo/SeoComponent";
import { t } from "i18next";
export const Home = () => {
  // CHECK IF USER TOKEN AVAILABLE OR NOT
  const userToken = useSelector((state) => state?.user?.userData)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  }, [])

  // GOOGLE ANALYTICS
  useEffect(() => {
    ReactGA4.event({
      category: "dashboard",
      action: "visit",
      id: Date.now(),
      item: "Amaken Dashboard",
      description: "Home screen is visited."
    });
  }, [])

  return (
    <div className="body_content">
      {/* SEO IMPLEMENTATION */}
      <SeoComponent
        title={t("HOME_PAGE_TITLE")}
        meta_title={t("HOME_PAGE_TITLE")}
        description={t("HOME_PAGE_DESCRIPTION")}
        keywords={"Amaken, real estate, luxury apartments, commercial, residential, property investment, dream home, buy property,  homes for sale, property search,  residential properties, commercial properties"}
        canonical={process.env.REACT_APP_WEBSITE_WEB_URL}
      />
      {/* Home Banner Style V1 */}
      <HomeBanner />
      {/* Explore Apartment */}
      <ExploreProperties />
      {/* PROPERTIES FOR YOU */}
      <section className="mt200 pt0 pb60 pb30-md bgc-white">
        {
          userToken && userToken !== null ? <PropertiesForYou /> : ""
        }
      </section>
      {/* Property Cities */}
      <PropertiesCities />
      {/* Our Partners */}
      <TrustedAgencies />
      {/* LOGIN AGENCY */}
      <LoginAgency />
      {/* Our Footer */}
      <Footer />
    </div>

  );
};
